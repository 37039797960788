import { KeycloakService } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import { EnumDTO } from 'common/type';

export const getModulos = (): AxiosPromise<EnumDTO[]> =>
  axios.get(
    `${
      KeycloakService.getInstance().authServerUrl
    }/realms/OxyElotech/cliente/modulos`
  );
