import { KeycloakService, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import {
  Cliente,
  DashboardDTO,
  DashboardInfo,
  Entidade,
  EnumDTO,
  ModuloConfig
} from '../type';

const resource = () =>
  `${KeycloakService.getInstance().authServerUrl}/realms/${
    KeycloakService.getInstance().realm
  }/cliente`;

const defaultSort = {
  sort: 'nome,asc'
};

export const search = (
  searchParams: string,
  page: any,
  sort = defaultSort
): AxiosPromise<PagedResponse<Cliente>> => {
  return axios.get(`${resource()}`, {
    params: { search: searchParams, ...sort, ...page }
  });
};

export const findById = (id: string): AxiosPromise<Cliente> => {
  return axios.get(`${resource()}/${id}`);
};

export const save = (cliente: Cliente): AxiosPromise<Cliente> => {
  return cliente.id
    ? axios.put(`${resource()}/${cliente.id}`, cliente)
    : axios.post(resource(), cliente);
};

export const getInformacoesDashboard = (): AxiosPromise<DashboardDTO> => {
  return axios.get(`${resource()}/dashboard`);
};

export const getModulosConfig = (id: string): AxiosPromise<ModuloConfig[]> =>
  axios.get(`${resource()}/${id}/modulo-config`);

export const saveModuloConfig = (
  id: string,
  modulos: ModuloConfig[]
): AxiosPromise<ModuloConfig[]> =>
  axios.put(`${resource()}/${id}/modulo-config`, modulos);

export const getModulos = (): AxiosPromise<EnumDTO[]> =>
  axios.get(`${resource()}/atual/modulos`);

export const getEntidades = (search: string): AxiosPromise<Entidade[]> =>
  axios.get(`${resource()}/atual/entidades`, {
    params: { search }
  });

export const getContratantes = (): AxiosPromise<string[]> =>
  axios.get(`${resource()}/contratantes`);

export const dashboardInfo = (tenant: string): AxiosPromise<DashboardInfo> =>
  axios.get(`/console-api/api/clientes/dashboard`, {
    params: { tenant: tenant }
  });
